import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDataIterator,{attrs:{"items-per-page":1000,"items":_vm.filteredSellers,"loading":_vm.vm.loading,"no-data-text":_vm.vm.mounted ? 'Ingen lærere funnet.' : 'Søk for å laste inn lærere.',"loading-text":"Laster inn lærere..."},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VToolbar,{staticClass:"py-2 mb-4",attrs:{"flat":""}},[[_c(VSpacer),_c(VCheckbox,{attrs:{"label":"Godkjent dokumentasjon"},model:{value:(_vm.vm.filters.onlyApprovedSellers),callback:function ($$v) {_vm.$set(_vm.vm.filters, "onlyApprovedSellers", $$v)},expression:"vm.filters.onlyApprovedSellers"}}),_c(VSpacer),_c(VCheckbox,{attrs:{"label":"Kun Pros"},model:{value:(_vm.vm.filters.onlyPros),callback:function ($$v) {_vm.$set(_vm.vm.filters, "onlyPros", $$v)},expression:"vm.filters.onlyPros"}}),_c(VSpacer),_c(VAutocomplete,{attrs:{"items":Object.values(_vm.activeCategories),"item-text":"title","item-value":"ID","multiple":"","chips":"","label":"Filtrer etter fag","outlined":"","dense":""},model:{value:(_vm.vm.filters.selectedCategories),callback:function ($$v) {_vm.$set(_vm.vm.filters, "selectedCategories", $$v)},expression:"vm.filters.selectedCategories"}}),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","color":"primary","loading":_vm.vm.loading},on:{"click":function($event){return _vm.search()}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)]],2)]},proxy:true},{key:"default",fn:function(props){return [_c(VRow,_vm._l((props.items),function(item){return _c(VCol,{key:item.user.uid,attrs:{"cols":"12","sm":"4","lg":"3","xl":"2"}},[_c('seller-thumbnail',{attrs:{"seller":item},on:{"refresh":function($event){return _vm.search()}}})],1)}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }